import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'app/_helpers/base/base.service';
import { FormControl, FormGroup} from '@angular/forms';
import { startWith, tap, map as Map, takeUntil, finalize } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { GLOBALS } from 'app/config/globals';
import { AttachmentsService } from 'app/services/attachments.service';
import * as _ from 'lodash';
import { WhatsappConfirmSendDialogComponent } from './whatsapp-confirm-send-dialog/whatsapp-confirm-send-dialog.component';

@Component({
    selector: 'whatsapp-message',
    templateUrl: './whatsapp-message.component.html',
    styleUrls: ['./whatsapp-message.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WhatsappMessageComponent implements OnInit {

    composeForm: FormGroup;
    ids: any = [];
    displayedColumns: string[] = ['name', 'actions'];
    loadingResults: boolean;
    activeEditor: any;
    filteredTemplates: any[];
    public tinyMc = GLOBALS.tinyMce; // To use centralized tinyMce
    public availableTemplates: any[] = [];
    templateSearch: FormControl = new FormControl('');
    searching: boolean;
    templateLanguage: string = '';
    private _unsubscribeAll: Subject<any>;
    attachments: string[] = [];
    loadigResult: boolean = false;
    isAttachmentRequired: boolean = false;


    constructor(
        public matDialogRef: MatDialogRef<WhatsappMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _toaster: ToastrService,
        private _baseService: BaseService,
        private _attachmentsService: AttachmentsService,
        public _matDialog: MatDialog,

    ) {
        this.tinyMc.setup = (editor) => {
            editor.on('init', (e) => {
                this.activeEditor = editor;
            });
        };
        // this.tinyMc['readonly'] = true;
        // console.log("🚀 ~ WhatsappMessageComponent ~ this.tinyMc:", this.tinyMc)
        this.ids = this._data.ids;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.getWaTemplates();
        this.composeForm = this.createComposeForm();
    }

    getWaTemplates() {
        this._baseService.get('external-service/wa-templates', 'nodejs').subscribe((data: any) => {
            this.availableTemplates = data?.data;
            this.filteredTemplates = data?.data;
            this.filterTemplate();
        });
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            template: new FormControl(""),
            template_language: new FormControl(""),
            content: new FormControl(""),
            components: new FormControl(""),
        });
    }

    filterTemplate(): void{
        this.templateSearch.valueChanges
        .pipe(
            startWith(''),
            tap(() => this.searching = true),
            Map((search: string) => {
                const data = this.availableTemplates
                return data.filter(template => template.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
            })
        )
        .subscribe((data: any[]) => {
            this.filteredTemplates = data;
            this.searching = false;
        });
    }

    templateSelect() {
        if (!this.composeForm.get('template').value) {
            this.composeForm.get('content').setValue('');
        } else {
            const selectedTemplate = this.availableTemplates.filter(template => template.name === this.composeForm.get('template').value);
            if (selectedTemplate.length) {
                this.composeForm.get('content').setValue(
                    this.prepareMessage(selectedTemplate)
                )
                this.composeForm.get('template_language').setValue(selectedTemplate[0]?.language);
                this.composeForm.get('components').setValue(selectedTemplate[0].components);
            }
        }
    }

    sendMessage(){
        const templateValue = this.composeForm.get('template')?.value;
        if (!templateValue) {
             this._snackBar.open(this._translateService.instant("Plz Select Template to send."), this._translateService.instant('Close'), {
                duration: 3000,
            });
            return;
        }

        if(this.isAttachmentRequired && (!this.attachments || this.attachments.length == 0)){
            this._snackBar.open(this._translateService.instant("Plz Select Attachment."), this._translateService.instant('Close'), {
                duration: 3000,
            });
            return
        }

        

        let confirmDialog = this._matDialog.open(WhatsappConfirmSendDialogComponent,{
            disableClose: true,
            data:{
                count: this.ids.length
            }
        })
        confirmDialog.afterClosed().subscribe((result)=>{

            if(result){
                let dataToPost = this.composeForm.getRawValue();
                if(this.attachments.length){
                    dataToPost['attachments'] = this.attachments;
                }
                this.matDialogRef.close(dataToPost);

                this._snackBar.open(this._translateService.instant("Messages are being send in background. You will recieve notification once successfully send."), this._translateService.instant('Close'), {
                duration: 3000,
                });
            }
        })
    }

    convertNewlinesAndEscapeHtml(input) {
        let escapedHtml = this.escapeHtml(input);
        return escapedHtml.replace(/\n/g, "<br>");
    }

    escapeHtml(str) {
        return str.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    onSelectFile(files: FileList): void {

        const file = files[0]
        let size = file.size
        if (file.type.startsWith('image/') && size > 5242880) { // 5MB size in bytes
            this._snackBar.open(this._translateService.instant('Image size must be less than 5MB'), this._translateService.instant('Close'), {
            duration: 3000,
            });
            this.removeAttachment(files);
            return
        } else if (file.type.startsWith('application/') && size > 104857600) { // 100MB size in bytes
            this._snackBar.open(this._translateService.instant('Document size must be less than 100MB'), this._translateService.instant('Close'), {
            duration: 3000,
            });
            this.removeAttachment(files);
            return
        } 
        const uploader = [];
        this.loadigResult = true;
        uploader.push(this._attachmentsService.mailAttachment(file, 'whatsapp'));
        forkJoin(uploader)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => this.loadigResult = false) 
            )
            .subscribe((data: any) => {
                const attachments = _.map(data, (url) => {
                    let rootUrl = 'https://crm.optimaventurez.com';
                    return rootUrl+'/images/whatsapp/'+this.currentDate()+'/'+_.last(_.split(url, '/'));
                });
                this.attachments = _.concat(this.attachments, attachments);
            }, () => {});
       
    }

    currentDate(){
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        return month + "-" + day + "-" + now.getFullYear();
    }

    prepareMessage(selectedTemplate) {
        let message = '';
        for (const component of selectedTemplate[0].components) {
            const type = component?.type.trim().toLowerCase();
            if (type === 'header') {
                const format = component?.format.trim().toLowerCase(); // `component.format` only available in header type
                if (format === 'text') {
                    message += component?.text;
                    this.isAttachmentRequired = false
                } else if (format === 'document' || format === 'image') {
                    message += '{{Attachment}}';
                    this.isAttachmentRequired = true
                }
            }
            else if (type === 'body') {
                if (message.length) {
                    message += '\n\n';
                }
                message += component?.text;
            }
            else if (type === 'footer') {
                message += `\n\n${component?.text}`;
            }
        }
        return this.convertNewlinesAndEscapeHtml(message);
    }

    removeAttachment(file: any): void {
        _.remove(this.attachments, (el: any) => {
            return el === file;
        });
    }

}
